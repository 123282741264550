<template>
  <div>
    <!-- Results -->
    <b-modal id="results" no-close-on-backdrop>
      <template #modal-header>
        <!-- 2-Wochen-Kontaktlinsen -->
        <div v-if="stringOptions.startsWith('2,5')">
          <h5 class="mb-5">
            {{ getRecommendationName(results[6]) }}
          </h5>
          <p>
            {{ results[7] }}
          </p>
        </div>
        <div v-else-if="stringOptions.startsWith('5,2')">
          <h5 class="mb-5">
            {{ getRecommendationName(results[6]) }}
          </h5>
          <p>
            {{ results[7] }}
          </p>
        </div>

        <!-- Monats-Kontaktlinsen -->
        <div v-else-if="stringOptions == '2'">
          <h5 class="mb-5">{{ getRecommendationName(results[0]) }}</h5>
          <p>
            {{ results[1] }}
          </p>
        </div>

        <!-- Ein-Tages-Kontaktlinsen --->
        <div v-else>
          <h5 class="mb-5">{{ getRecommendationName(results[2]) }}</h5>
          <p v-html="results[3]"></p>
        </div>
      </template>

      <!-- Answers list -->
      <template>
        <ul class="answers-list">
          <li v-for="answer in uniqueAnswers" :key="answer.index">
            {{ answer }}
          </li>
        </ul>
      </template>
      <hr />

      <!-- Info link -->
      <template>
        <ul class="list-inline info-link">
          <li v-html="results[4]" class="list-inline-item"></li>
        </ul>
      </template>

      <!-- Neu auswhal Btn -->
      <template #modal-footer="{ cancel }">
        <button
          class="btn-custom btn-small"
          @click="
            cancel();
            reset();
          "
        >
          {{ results[5] }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
.answers-list {
  li {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
.info-link {
  margin-bottom: -15px;
  u {
    color: black;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import Axios from "axios";
export default {
  name: "Results",
  data() {
    return {
      results: [],
    };
  },
  mounted() {
    this.getResults();
  },
  computed: {
    ...mapState([
      "uniqueAnswers",
      "options",
      "language",
      "selectionIds",
      "recommendationName",
    ]),
    ...mapGetters(["recommendationId"]),
    stringOptions() {
      return this.options.toString();
    },
  },
  methods: {
    getResults() {
      Axios.get("json/results.json").then((response) => {
        this.results = response.data[this.language];
      });
    },
    getRecommendationName(title) {
      this.$store.commit("setRecommendationName", title);
      return title;
    },
    reset() {
      this.$store.commit("reset");
    },
    gtmTrackStoreAndRecomm() {
      setTimeout(() => {
        this.$gtm.trackEvent({
          event: "customEvent",
          storeId: this.$cookie.get("StoreId"),
          storeName: this.$cookie.get("StoreName"),
          eventType: "selectionSubmission",
          eventActionId: this.recommendationId,
          eventLabel: this.$cookie.get("selectionIds"),
          eventAction: this.recommendationName,
          storeCountry: "CH",
        });
      }, 500);
    },
  },
  watch: {
    uniqueAnswers(fieldVal) {
      if (fieldVal.length == 3) {
        this.$bvModal.show("results");
        this.gtmTrackStoreAndRecomm();
      }
    },
  },
};
</script>
