<template>
  <div>
    <!-- Answers -->
    <b-row class="mt-4 answers-wrapper">
      <b-col
        md="3"
        class="answer"
        v-for="(card, index) in cards"
        :key="card.index"
        @click="addAnswer(index)"
      >
        <button class="btn-custom btn-answer">
          {{ card }}
        </button>
      </b-col>
    </b-row>

    <!-- Footer and Show Results button -->
    <b-row>
      <b-col md="10"><ChoicesFooter /></b-col>
      <b-col md="2">
        <transition appear name="route" mode="out-in">
          <div v-if="uniqueAnswers.length > 0">
            <button
              class="btn-custom float-right btn-small"
              @click="
                resetHover();
                gtmTrackStoreAndRecomm();
              "
              v-b-modal.results
            >
              {{ texts[6] }}
            </button>
          </div>
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss" scoped>
.answers-wrapper {
  position: relative;
}
.active {
  color: white;
  background: #333;
}
.btn-custom {
  position: relative;
  &:hover {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    background-image: url("../assets/check.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}
.btn-small {
  &:hover {
    background-image: none;
  }
}
</style>

<script>
import { mapState, mapGetters } from "vuex";
import ChoicesFooter from "@/components/ChoicesFooter.vue";
import Axios from "axios";

export default {
  name: "Choices",
  components: {
    ChoicesFooter,
  },
  data() {
    return {
      cards: [],
    };
  },
  mounted() {
    this.getCards();
    this.gtmTrackStore();
    this.getTexts();
    this.changeLanguage();
  },
  computed: {
    ...mapState([
      "uniqueAnswers",
      "language",
      "selectionIds",
      "texts",
      "recommendationName",
    ]),
    ...mapGetters(["recommendationId"]),
    answersDOM() {
      let arr = document
        .querySelector(".answers-wrapper")
        .querySelectorAll(".btn-answer");
      return arr;
    },
  },
  methods: {
    addAnswer(index) {
      this.$store.commit("addAnswer", index);
      this.answersDOM[index].classList.toggle("active");
      this.setCookies(index);
      this.gtmTrackStoreAndCard(index);
      this.removeDeSelectedOptions(index);
    },
    setCookies(index) {
      this.$store.commit("setSelectionIds", index);
      this.$cookie.set("selectionIds", this.selectionIds);
    },
    gtmTrackStore() {
      this.$gtm.trackEvent({
        event: "siteInfo",
        storeId: this.$cookie.get("StoreId"),
        storeName: this.$cookie.get("StoreName"),
        storeCountry: "CH",
      });
    },
    gtmTrackStoreAndCard(index) {
      this.$gtm.trackEvent({
        event: "customEvent",
        storeId: this.$cookie.get("StoreId"),
        storeName: this.$cookie.get("StoreName"),
        // if it has < 2 classes, means it has .active class therefore it is deSelection
        eventType: event.target.classList.contains("active")
          ? "selection"
          : "deSelection",
        eventActionId: index,
        eventAction: event.target.textContent,
        storeCountry: "CH",
      });
    },
    removeDeSelectedOptions(index) {
      let isActive = event.target.classList.contains("active");
      if (isActive == false) {
        this.$store.commit("clearOption", index);
      }
    },
    gtmTrackStoreAndRecomm() {
      setTimeout(() => {
        this.$gtm.trackEvent({
          event: "customEvent",
          storeId: this.$cookie.get("StoreId"),
          storeName: this.$cookie.get("StoreName"),
          eventType: "selectionSubmission",
          eventActionId: this.recommendationId,
          eventLabel: this.$cookie.get("selectionIds"),
          eventAction: this.recommendationName,
          storeCountry: "CH",
        });
      }, 500);
    },
    resetHover() {
      for (let i = 0; i < this.answersDOM.length; i++) {
        this.answersDOM[i].classList.remove("active");
      }
    },
    getCards() {
      Axios.get("json/answers.json").then((response) => {
        this.cards = response.data[this.language];
      });
    },
    getTexts() {
      this.$store.commit("getTexts");
    },
    changeLanguage() {
      let currentSelectedLanguage = this.$cookie.get("language");
      this.$store.commit("changeLanguage", currentSelectedLanguage);
    },
  },
  watch: {
    uniqueAnswers(fieldVal) {
      if (fieldVal.length >= 3) {
        this.resetHover();
      }
    },
  },
};
</script>
