<template>
  <div>
    <b-container>
      <Choices />
      <Results />
    </b-container>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import Choices from "@/components/Choices.vue";
import Results from "@/components/Results.vue";

export default {
  name: "Home",
  components: {
    Choices,
    Results,
  },
};
</script>
