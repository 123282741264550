import { render, staticRenderFns } from "./Choices.vue?vue&type=template&id=4711f3be&scoped=true&"
import script from "./Choices.vue?vue&type=script&lang=js&"
export * from "./Choices.vue?vue&type=script&lang=js&"
import style0 from "./Choices.vue?vue&type=style&index=0&id=4711f3be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4711f3be",
  null
  
)

export default component.exports