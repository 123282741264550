<template>
  <div>
    <ul class="list-inline">
      <li class="list-inline-item">
        <button @click="backToHome" class="overview-btn">
          {{ texts[3] }}
        </button>
      </li>
      <li class="list-inline-item">
        <a
          href="https://corporate.fielmann.com/de/investor-relations/?_ga=2.100072670.1516475833.1613925636-1241324473.1612523425"
          >Fielmann AG |</a
        >
      </li>
      <li class="list-inline-item">
        <a href="https://www.fielmann.ch/de/datenschutz/"> {{ texts[4] }} |</a>
      </li>
      <li class="list-inline-item">
        <a href="https://www.fielmann.ch/de/impressum/"> {{ texts[5] }}</a>
      </li>
    </ul>
    <!-- New Client btn -->
    <ul class="list-unstyled">
      <li>
        <button @click="resetGaId()" class="overview-btn">
          {{ texts[7] }}
        </button>
      </li>
    </ul>
    <!-- Alert after clicking Client btn -->
    <b-alert :show="showNewClientMsg">
      {{ texts[8] }}
      <div @click="showNewClientMsg = false" class="close">
        <a href="#">x</a>
      </div>
    </b-alert>
  </div>
</template>

<style lang="scss" scoped>
a {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}
.overview-btn {
  background: white;
  font-size: 12px;
  border: 1px solid lightgrey;
  padding: 10px 10px 10px 10px;
  text-transform: uppercase;
}
</style>

<script>
export default {
  data() {
    return {
      showNewClientMsg: false,
    };
  },
  mounted() {
    this.getTexts();
  },
  computed: {
    texts() {
      return this.$store.state.texts;
    },
  },
  methods: {
    getTexts() {
      this.$store.commit("getTexts");
    },
    resetGaId() {
      this.$cookie.delete("_gid");
      this.$cookie.delete("_ga");
      window.location.reload();
      this.showNewClientMsg = true;
    },
    backToHome() {
      this.$cookie.delete("StoreSelected");
      this.$router.push("/");
    },
  },
};
</script>
